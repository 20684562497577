import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Cloud Services",
  sectionHeader: "Cloud Services that adapt to your changing needs",
  sectionContent:
    "We know that your business needs are constantly changing. With goSystem Cloud Services, we provide on-demand tools that grow and adapt to your needs. Whether you are a sole-trader looking for cloud email, or an international enterprise looking for a managed Microsoft 365 solution, goSystem Cloud Services have the tools you need to maximise productivity, scalability, security and communication with the cloud.",
  splitTag: "Always Online",
  splitHeading: "Guaranteed uptime and industry-leading support",
  splitContent:
    "We pride ourselves on our industry-leading support, with named support advisors, 24/7 incident response, and an average response time of just 3 minutes. We guarantee that you will never be left without the tools you need to succeed. With a 99.98% SLA on our cloud services and 100% SLA on mission-critical services our services are always online.",
  splitImg: "Antenna",
  servicesHeading: "Our Cloud Services",
  servicesCards: [
    {
      title: "Managed Cloud Services",
      body: "We provide a wide range of managed cloud services, from scalable cloud infrastructure, AWS and Azure deployments and cloud federation services.",
      img: "CLOUD",
    },
    {
      title: "Cloud Hosting",
      body: "goSystem provides affordable cloud hosting solutions to ensure your website, API, or web app is always accessible, fast and secure. With infrastructure in 7 data centres, reliability is guaranteed.",
      img: "WORLD_WIDE_WEB",
    },
    {
      title: "Managed 365 & Productivity",
      body: "goSystem provides fully managed Microsoft 365 solutions, ensuring your employees have the tools they need to maximise productivity and communication. From single users to thousands of managed accounts.",
      img: "CONNECT_DEVELOP",
    },
  ],
  callToAction: "Discover what goSystem can do for you",
};

export default () => ServicesTemplate(config);
